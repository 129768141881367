import { createStore } from 'vuex'
import axios from 'axios';
import createPersistedState from "vuex-persistedstate";
import { ADMIN_URL } from '../constants';

const store = createStore({
    state: {
        isLoggedIn: false,
        user: null,
        sidebarVisible: '',
        sidebarUnfoldable: false,
    },
    mutations: {
        toggleSidebar(state) {
            state.sidebarVisible = !state.sidebarVisible
        },
        toggleUnfoldable(state) {
            state.sidebarUnfoldable = !state.sidebarUnfoldable
        },
        updateSidebarVisible(state, payload) {
            state.sidebarVisible = payload.value
        },
        SET_USER(state, user) {
            state.isLoggedIn = true;
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));  // Persist in local storage
        },
        LOGOUT(state) {
            state.isLoggedIn = false;
            state.user = null;
            localStorage.removeItem('user');  // Clear local storage
        },
        UPDATE_USER_PROFILE(state, updatedUser) {
            state.user.full_name = updatedUser.full_name;
            state.user.email = updatedUser.email;
            state.user.profile_image = updatedUser.profile_image;
            localStorage.setItem('user', JSON.stringify(state.user));  // Update local storage
        },
    },
    actions: {
        initializeStore({ commit }) {
            const user = localStorage.getItem('user');
            if (user) {
                commit('SET_USER', JSON.parse(user));
            }
        },
        login({ commit }, credentials) {
            return new Promise((resolve, reject) => {
                axios.post(ADMIN_URL + '/login', credentials)
                    .then((response) => {
                        if (response.data && response.data.success == true) {
                            const user = response.data.data;
                            commit('SET_USER', user);
                            resolve(user);
                        } else {
                            reject(response.data.message);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        logout({ commit }) {
            commit('LOGOUT');
        },
        updateUserProfile({ commit }, updatedUser) {
            commit('UPDATE_USER_PROFILE', updatedUser);
        }
    },
    getters: {
        isLoggedIn: (state) => state.isLoggedIn,
        currentUser: (state) => state.user,
        permissions: (state) => state.user ? state.user.permissions : [],
    },
    plugins: [createPersistedState()],
});

export default store;