<template>
	<div>
		<AdminSidebar />
		<div class="wrapper d-flex flex-column min-vh-100 bg-light">
			<adminHeader />
			<div class="body flex-grow-1 px-3">
				<!-- <CContainer lg> -->
				<CContainer fluid>
					<slot></slot>
				</CContainer>
			</div>
			<adminFooter />
		</div>
	</div>
</template>

<script>
import { CContainer } from '@coreui/vue'
import AdminFooter from '@/components/AdminFooter.vue'
import AdminHeader from '@/components/AdminHeader.vue'
import AdminSidebar from '@/components/AdminSideBar.vue'

export default {
	name: 'AdminLayout',
	components: {
		AdminFooter,
		AdminHeader,
		AdminSidebar,
		CContainer,
	},
}
</script>

<style lang="css">
.sidebar.sidebar-fixed{
	background-color: #436850;
}

.sidebar-nav .nav-link.active{
	/* background: #D4A2A0 !important; */
	/* background: linear-gradient(90deg, #1E075E 0%, #390671 22.87%, #550669 48.00%, #710761 74.52%, #AD0344 100%) !important; */
	background-color: #436850;

}
.sidebar-nav .nav-link:hover{
	/* background: #5A461C !important; 
	border-radius: 30px;*/
	/* background: radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.04) 77.08%, rgba(255, 255, 255, 0.00) 100%) !important; 
	backdrop-filter: blur(5.654404640197754px) !important;  */

	background: radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.04) 77.08%, rgba(255, 255, 255, 0.00) 100%);

}
</style>