<template>
	<CSidebar
		position="fixed"
		:unfoldable="sidebarUnfoldable"
		:visible="sidebarVisible"
		@visible-change="
		(event) =>
			$store.commit({
			type: 'updateSidebarVisible',
			value: event,
		})
	">
		<CSidebarBrand>
			<img class="sidebar-brand-full" alt="aptc Logo" width="100" :src="require('@/assets/img/Logo.png')" />
			<img class="sidebar-brand-narrow" width="63" :src="require('@/assets/img/Logo.png')" />
		</CSidebarBrand>
		<CSidebarNav>
		<CNavItem>
			<router-link class="nav-link" :to="{ name: 'Dashboard' }"><CIcon  customClassName="nav-icon" icon="cil-speedometer"/> Dashboard</router-link>
		</CNavItem>
		<CNavItem>
			<router-link :class="{ active: isRouteActive(['UserList','UserEdit']) }" class="nav-link" :to="{ name: 'UserList' }"><CIcon  customClassName="nav-icon" icon="cil-people"/> User Management</router-link>
		</CNavItem>
		<CNavItem>
			<router-link :class="{ active: isRouteActive(['PostList','PostCreate','PostEdit']) }" class="nav-link" :to="{ name: 'PostList' }"><CIcon  customClassName="nav-icon" icon="cil-notes"/> Post Management</router-link>
		</CNavItem>
		<CNavItem>
			<router-link :class="{ active: isRouteActive(['UserSubscriptionList']) }" class="nav-link" :to="{ name: 'UserSubscriptionList' }"><CIcon  customClassName="nav-icon" icon="cil-money"/> User Subscription List</router-link>
		</CNavItem>
		<CNavItem>
			<router-link :class="{ active: isRouteActive(['MemberFeesList','MemberFeesCreate','MemberFeesEdit']) }" class="nav-link" :to="{ name: 'MemberFeesList' }"><CIcon  customClassName="nav-icon" icon="cil-dollar"/> Members Fees Management</router-link>
		</CNavItem>
		<CNavItem>
			<router-link :class="{ active: isRouteActive(['MessageList']) }" class="nav-link" :to="{ name: 'MessageList' }"><CIcon  customClassName="nav-icon" icon="cil-chat-bubble"/>Message Management</router-link>
		</CNavItem>
		<CNavItem>
			<router-link :class="{ active: isRouteActive(['HotelList','HotelCreate','HotelEdit']) }" class="nav-link" :to="{ name: 'HotelList' }"><CIcon  customClassName="nav-icon" icon="cil-bank"/>Hotel Management</router-link>
		</CNavItem>
	</CSidebarNav>
		<CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleUnfoldable')"/>
	</CSidebar>
</template>
  
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
// import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
export default {
	name: 'AppSidebar',
	// components: {
	// 	AppSidebarNav,
	// },
	setup() {
		const store = useStore()
		return {
			logoNegative,
			sygnet,
			sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
			sidebarVisible: computed(() => store.state.sidebarVisible),
		}
	},
	methods: {
		isRouteActive(routeName) {
			return routeName.includes(this.$route.name);
		}
	}
}
</script>

<style scoped>

</style>
