<template>
  <!-- <div id="app"> -->
    <Layout>
      <router-view />
    </Layout>
  <!-- </div> -->
</template>

<script>
export default {
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>