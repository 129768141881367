<template>
	<div>
		<i class="c-icon c-icon-xl cil-pencil color-green pointer" @click="onEditClick()"></i>
		<i class="c-icon c-icon-xl cil-trash color-red pointer" @click="onDeleteClick()"></i>
	</div>
</template>
  
<script>
import Swal from 'sweetalert2'; 
import axios from 'axios';
export default {
	methods: {
		onEditClick() {
			// Handle edit action here
			const data = this.params.data; // The data object associated with the row
			//console.log("Edit:", data);
			this.$router.push({ name: "RoleEdit", params: { id: data.id } });
		},
		onDeleteClick() {
			this.$emit('delete', this.$attrs.rowIndex);
			// Handle delete action here
			const deleteData = this.params.data; // The data object associated with the row
			//console.log("Delete:", deleteData.delete_url);
			Swal.fire({
				title: 'Delete Item',
				text: `Are you sure you want to delete?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Delete',
				confirmButtonColor: '#d33',
				cancelButtonText: 'Cancel',
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					axios.delete(`${deleteData.delete_url}`)
					.then((response) => {
						if(response.data && response.data.success == true){
							Swal.fire({
								title: 'Deleted!',
								text: response.data.message,
								icon: 'success',
							});
							//const { node } = this.params;
							this.$emit('delete-row', deleteData);
							// Remove the row from the rowData array
							//this.rowData.splice(this.params.rowIndex, 1);
						} else {
							Swal.fire({
								title: 'Delete Failed',
								text: response.data.message,
								icon: 'error',
							});
						}
					})
					.catch((error) => {
						Swal.fire({
							title: 'Delete Failed',
							text: error,
							icon: 'error',
						});
					});
					
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					Swal.fire('Cancelled', 'Your item is safe :)', 'info');
				}
			});
		},
	},
	created() {
		// Access the cell's data using this.params.data
	},
};
</script>
<style>
/* Load CoreUI Icons CSS */
@import "~@coreui/icons/css/free.min.css";
/* Customize the icon size as needed */
.c-icon {
font-size: 18px;
padding: 5px;
margin-top: 10px;
}
.color-red{
color: #ff0000;
}
.color-green{
color: #008000;
}
.pointer {cursor: pointer;}
</style>