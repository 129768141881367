import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import AdminLayout from '@/layouts/AdminLayout.vue'
import store from '../store'

const routes = [
	{
		path: '/',
		name: 'Home',
		redirect: '/',
		meta: { layout: DefaultLayout },
		children: [
			{
				path: '/',
				name: 'Home',
				component: () => import('@/views/HomePage'),
				requiresAdminAuth: false,
			},
			{
				path: '/privacy-policy',
				name: 'PrivacyPolicy',
				component: () => import('@/views/user/privacy-policy'),
				requiresAdminAuth: false,
			},
			{
				path: '/license',
				name: 'LicensePage',
				component: () => import('@/views/user/license'),
				requiresAdminAuth: false,
			},
			{
				path: '/terms',
				name: 'TermsCondition',
				component: () => import('@/views/user/terms-condition'),
				requiresAdminAuth: false,
			},
			{
				path: '/delete-account',
				name: 'VerifyDeleteAccount',
				component: () => import('@/views/user/VerifyAccountPage'),
				requiresAdminAuth: false,
			},
			{
				path: 'password/reset/:token',
				name: 'UserResetPassword',
				component: () => import('@/views/user/auth/passwords/ResetPassword'),
			},
		]
	},
	{
		path: '/admin',
		redirect: '/admin/login',
		name: 'Admin',
		meta: { layout: AdminLayout },
		children: [
			{
				path: 'login',
				name: 'Login',
				component: () => import('@/views/admin/auth/Login'),
				requiresAdminAuth: false,
			},
			{
				path: 'password/reset',
				name: 'ForgotPassword',
				component: () => import('@/views/admin/auth/passwords/ForgotPassword.vue'),
				requiresAdminAuth: false,
			},
			{
				path: 'password/reset/:token',
				name: 'ResetPassword',
				component: () => import('@/views/admin/auth/passwords/ResetPassword'),
				requiresAdminAuth: false,
			},
			{
				path: 'dashboard',
				name: 'Dashboard',
				component: () => import('@/views/admin/AdminDashboard'),
				meta: {
					title: 'Dashboard - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The dashboard page of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The dashboard page of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'users',
				name: 'UserList',
				component: () => import('@/views/admin/user/UserList'),
				meta: {
					title: 'Users - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The users management of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The users list of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'user/:id/edit',
				name: 'UserEdit',
				component: () => import('@/views/admin/user/UserEdit'),
				meta: {
					title: 'Users - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The users management of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The users list of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'posts',
				name: 'PostList',
				component: () => import('@/views/admin/post/PostList'),
				meta: {
					title: 'Posts - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The posts management of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The posts list of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'posts/create',
				name: 'PostCreate',
				component: () => import('@/views/admin/post/PostCreate'),
				meta: {
					title: 'Posts - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The posts management of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The posts list of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'posts/:id/edit',
				name: 'PostEdit',
				component: () => import('@/views/admin/post/PostEdit'),
				meta: {
					title: 'Posts - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The posts management of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The posts list of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'user-subscription/list',
				name: 'UserSubscriptionList',
				component: () => import('@/views/admin/user_subscription/List'),
				meta: {
					title: 'User-Subscription - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The User-Subscription management of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The User-Subscription list of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'member-fees/list',
				name: 'MemberFeesList',
				component: () => import('@/views/admin/memberFees/List'),
				meta: {
					title: 'Members Fees - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The members fees management of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The members fees list of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'member-fees/create',
				name: 'MemberFeesCreate',
				component: () => import('@/views/admin/memberFees/Create'),
				meta: {
					title: 'Members Fees - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The members fees management of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The members fees list of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'member-fees/:id/edit',
				name: 'MemberFeesEdit',
				component: () => import('@/views/admin/memberFees/Edit'),
				meta: {
					title: 'Members Fees - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The members fees management of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The members fees list of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'message/list',
				name: 'MessageList',
				component: () => import('@/views/admin/message/List'),
				meta: {
					title: 'Message - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The Message management of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The Message list of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'hotel/list',
				name: 'HotelList',
				component: () => import('@/views/admin/hotel/List'),
				meta: {
					title: 'Hotel - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The Hotel management of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The Hotel list of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'hotel/create',
				name: 'HotelCreate',
				component: () => import('@/views/admin/hotel/Create'),
				meta: {
					title: 'Hotel - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The Hotel management of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The Hotel list of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'hotel/:id/edit',
				name: 'HotelEdit',
				component: () => import('@/views/admin/hotel/Edit'),
				meta: {
					title: 'Hotel - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The Hotel management of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The Hotel list of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: '403',
				name: '403',
				component: () => import('@/views/admin/AdminDashboard'),
				meta: {
					title: 'Dashboard - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The dashboard page of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The dashboard page of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'profile-update',
				name: 'ProfileUpdate',
				component: () => import('@/views/admin/ProfileUpdate'),
				meta: {
					title: 'Dashboard - profile-update',
					metaTags: [{name: 'description',content: 'The login user profile update page of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The dashboard page of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'change-password',
				name: 'ChangePassword',
				component: () => import('@/views/admin/ChangePassword'),
				meta: {
					title: 'Dashboard - Association of Professional Tour Caddies',
					metaTags: [{name: 'description',content: 'The login user change their password of our Association of Professional Tour Caddies.'},{property: 'og:description',content: 'The dashboard page of our Association of Professional Tour Caddies.'}],
					requiresAdminAuth: true,
				}
			}
		],
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	},
  })

router.beforeEach((to, from, next) => {
	// Set the title
	document.title = to.meta.title || 'Association of Professional Tour Caddies' // Default title if not set

	// Set the meta tags
	const metaTags = to.meta.metaTags
	if (metaTags) {
		metaTags.forEach(tag => {
			const { name, content } = tag
			const metaTag = document.createElement('meta')
			metaTag.setAttribute(name, content)
			document.head.appendChild(metaTag)
		});
	}

	// Determine the layout based on the meta property of the route
	const layout = to.meta.layout || 'DefaultLayout'
	// Update the layout component dynamically based on the layout value
	if (layout === 'DefaultLayout') {
		router.app.$options.components.Layout = DefaultLayout
	} else if (layout === 'AdminLayout') {
		router.app.$options.components.Layout = AdminLayout
	}

	const isAdminLoggedIn = store.getters.isLoggedIn; // Implement the isLoggedIn getter in your store

	if (to.matched.some(route => route.meta.requiresAdminAuth) && isAdminLoggedIn === false) {
		// If the route requires authentication and the user is not logged in
		next('/admin');
	} else {
		if(to.path == '/admin/login' && isAdminLoggedIn === true){
			next('/admin/dashboard');
		}
	}

	if(isAdminLoggedIn === true && to.name != "Dashboard"){
		next();
	}

	next();
})

export default router
